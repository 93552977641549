import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StripeAccountLink } from '../classes/stripe-account-link';
import { StripeAccount } from '../classes/stripe-account';
import { StripeCheckout } from '../classes/stripe-checkout';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private http: HttpClient) { }

  createStripeAccount(): Observable<StripeAccount>{
    console.log('sending request for account creation');
    return this.http.get('https://us-central1-ivegotatruck-69afb.cloudfunctions.net/createExpressAccount') as Observable<StripeAccount>;
  }

  createStripeOnboardingLink(stripeAccountID: string): Observable<StripeAccountLink>{
    console.log('Creating Stripe Account Link');
    return this.http.post('https://us-central1-ivegotatruck-69afb.cloudfunctions.net/createAccountLink',
    {accountId: stripeAccountID}) as Observable<StripeAccountLink>;
  }

  deleteStripeAccount(stripeAccountID: string){
    console.log('sending request for account deletion');
    return this.http.post('https://us-central1-ivegotatruck-69afb.cloudfunctions.net/deleteExpressAccount',{accountId: stripeAccountID});

  }

  getStripeAccountDetails(stripeAccountID: string): Observable<StripeAccount>{
    console.log('sending request for account details');
    return this.http.post('https://us-central1-ivegotatruck-69afb.cloudfunctions.net/getAccountDetails',
    {accountId: stripeAccountID}) as Observable<StripeAccount>;
  }

  createPaymentLinkForBooking(driverAccountID: string, bookingAmount: number, bookingID: string): Observable<StripeCheckout>{
    console.log('sending request for payment');
    return this.http.post('https://us-central1-ivegotatruck-69afb.cloudfunctions.net/createPaymentLink',
    {driverAccountId: driverAccountID, bookingPrice: bookingAmount, bookingId: bookingID}) as Observable<StripeCheckout>;

  }

  getStripePaymentDetails(paymentID: string){
    console.log('sending request for payment details');
  }
}

/*
TESTING:
curl -d "driverAccountId=acct_1K2Fvp2HRLFCGuT7&bookingPrice=21000"
 https://us-central1-ivegotatruck-69afb.cloudfunctions.net/createPaymentLink


*/
