
export class Profile {
  uid = '';
  email = '';
  name = '';
  rating = 0;
  numRatings = 0;
  price = 0;
  hasTruck = false;
  lastLogin = 0;
  profilePictureURL = '';
  stripe = {
    accountId: '',
    payouts_enabled: false // eslint-disable-line
  };
  truck = {
    make: '',
    model: '',
    color: '',
    passengers: '',
    bedLength: 0,
    hasCanopy: false,
    available: ''
  };
  notificationToken: string;
  constructor(){

  }



}
