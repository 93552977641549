import { Component, OnInit } from '@angular/core';
import { StripeService } from 'src/app/services/stripe.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Profile } from 'src/app/classes/profile';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.page.html',
  styleUrls: ['./success.page.scss'],
})
export class SuccessPage implements OnInit {

  accountID: string;
  currentUser: Profile;

  constructor(
    private firebase: FirebaseService,
    private stripe: StripeService,
    private message: MessageService
    ) { }

  ngOnInit() {
    this.currentUser = this.firebase.getCurrentUser();

    if (this.currentUser.stripe.accountId){
      this.message.presentLoading('Checking...');
      this.getAccountDetails(this.currentUser.stripe.accountId);
    }
    console.log('hi');
    console.log(this.currentUser);

  }

  getAccountDetails(accountID: string){
    console.log('looking for account details');
    this.stripe.getStripeAccountDetails(accountID).subscribe(res =>{
      console.log('got stripe account details');
      console.log(res);
      this.currentUser.stripe.payouts_enabled = res.payouts_enabled;
      if(this.currentUser.stripe.payouts_enabled){
        console.log('payouts enabled');
        this.firebase.updateCurrentUser(this.currentUser);

        this.firebase.updateDocument('profiles',this.currentUser.uid,this.currentUser).then(result=>{
          console.log('firebase updated');
          this.message.dismissLoading();
        }).catch(error =>{
          this.message.dismissLoading();
          this.message.sendMessage('Onboarding Error');
        });

      } else {
        this.message.sendMessage('Onboarding Error');
      }

    },
    err=>{
      this.message.sendMessage('Error requesting account details.');
      this.message.dismissLoading();
    },
    () =>{

    });

  }

}

