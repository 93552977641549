import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public loading;

  constructor(private toastController: ToastController, private loadingController: LoadingController) { }

  async sendMessage(theMessage: string) {
    const toast = await this.toastController.create({
      message: theMessage,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  async presentLoading(withMessage: string) {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: withMessage,
      duration: 20000
    });
    await this.loading.present();
  }

  dismissLoading(){
    this.loading.dismiss();
  }

}
