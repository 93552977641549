import { Injectable } from '@angular/core';

/*
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireStorage } from '@angular/fire/compat/storage';
*/
import { Profile } from '../classes/profile';

import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from '@angular/fire/auth';
import { Firestore, collection, doc, setDoc, addDoc, getDoc, getDocs, query, where, orderBy, deleteDoc  } from '@angular/fire/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    ) {

    this.auth.onAuthStateChanged(user=>{
      if (user){
        const docRef = doc(this.firestore, 'profiles', user.uid);
        getDoc(docRef).then(res => {
          console.log('looked for profile');
          if (res.data()){
            localStorage.setItem('truckuser', JSON.stringify(res.data()));
          } else {
            localStorage.setItem('truckuser', JSON.stringify(new Profile()));
          }
        });

      } else {
        localStorage.setItem('truckuser', null);
      }
    });

  }

  updateCurrentUser(user: any){
    localStorage.setItem('truckuser', JSON.stringify(user));
  }

  createAccount(email,password){
    return createUserWithEmailAndPassword(this.auth,email,password);
  }

  createNewProfile(uid: string, email: string, name: string){
    const profile = new Profile();
    profile.email = email;
    profile.name = name;
    profile.uid = uid;
    const docRef = doc(this.firestore, 'profiles', uid);
    return setDoc(docRef, JSON.parse(JSON.stringify(profile)));
  }

  updateDocument(_collection: string, _document: string, data: any){
    const docRef = doc(this.firestore, _collection, _document);
    return setDoc(docRef, JSON.parse(JSON.stringify(data)), {merge: true});
  }

  createDocument(_collection: string, data: any){
    const docRef = collection(this.firestore, _collection);
    return addDoc(docRef, JSON.parse(JSON.stringify(data)));
  }

  getCurrentUser(){
    return JSON.parse(localStorage.getItem('truckuser'));
  }

  login(email,password){
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  logout(){
    return this.auth.signOut();
  }

  async getDocumentsFromCollectionOnce(_collection){
    const documents = [];

    (await getDocs(collection(this.firestore, _collection))).forEach((_doc) =>{
        documents.push(_doc.data());
    });

    return documents;

  }

  async getDocumentFromCollectionOnce(_collection, _document){
    return await (await getDoc(doc(this.firestore, _collection, _document))).data();
  }

  async getDocumentsFromCollectionWithSearchOnce(_collection, searchTerm, searchValue): Promise<any>{

    const returnedDocuments = [];

    const q = query(collection(this.firestore, _collection), where(searchTerm, '==', searchValue));

    (await getDocs(collection(this.firestore, _collection))).forEach((_doc) =>{
      let tempObject: any = {}; //have to do this since doc data does not normally include id
      tempObject = _doc.data();
      tempObject.id = _doc.id;

      returnedDocuments.push(tempObject);
    });

    return returnedDocuments;
  }

  async getDocumentsFromCollectionWithSearchOrderOnce(_collection, searchTerm, searchValue, _orderBy, _orderDirection){

    const returnedDocuments = [];

    const q = query(collection(this.firestore, _collection), where(searchTerm, '==', searchValue), orderBy(_orderBy, _orderDirection));

    (await getDocs(q)).forEach((_doc) =>{
      let tempObject: any = {}; //have to do this since doc data does not normally include id
      tempObject = _doc.data();
      tempObject.id = _doc.id;

      returnedDocuments.push(tempObject);
    });

    return returnedDocuments;
  }

  updateTimestamp(_collection, _document, timestampName, timestamp){
    const docRef = doc(this.firestore, _collection, _document);
    return setDoc(docRef, JSON.parse(JSON.stringify({[timestampName]: timestamp})), {merge: true});
  }

  async deleteDocument(_collection: string, documentID: string){
    return await deleteDoc(doc(this.firestore, _collection, documentID));
  }




  async uploadProfilePhoto(base64string: string, fileName: string): Promise<any>{

    return new Promise((resolve)=>{

    const storage = getStorage();
    // const photoRef = ref(storage, fileName+'.jpg');
    const photoImagesRef = ref(storage, '/profilephotos/'+fileName+'.jpg');

    const task = uploadString(photoImagesRef, base64string, 'base64');

    task.then(res =>{ //when upload task is complete, we can ask the storageRef for the DL URL
      getDownloadURL(photoImagesRef).then(url =>{
        resolve(url);
      });
    });

    });


  }

}
